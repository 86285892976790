type Props = React.SVGAttributes<SVGElement>

export const LocationSVG = (props: Props) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M7.99996 13C7.99996 13 11.8461 9.44607 11.8461 6.75C11.8461 4.67893 10.1241 3 7.99996 3C5.87579 3 4.15381 4.67893 4.15381 6.75C4.15381 9.44607 7.99996 13 7.99996 13ZM7.99996 8.625C6.93788 8.625 6.07689 7.78553 6.07689 6.75C6.07689 5.71447 6.93788 4.875 7.99996 4.875C9.06205 4.875 9.92304 5.71447 9.92304 6.75C9.92304 7.78553 9.06205 8.625 7.99996 8.625Z"
        fill={props?.fill || '#131313'}
      />
    </svg>
  )
}
