'use client'

import { FAVOURITE } from '@/constants'
import { useRegisterModal } from '@/hooks/use-register-modal'
import { useUser } from '@/hooks/use-user'
import { BookmarkIconSVG } from '@/components/svg/bookmark-icon/bookmark-icon'
import s from './bookmark-icon.module.css'
import cx from 'classnames'

export interface IBookmarkIconProps {
  itemId: number | string
  type: FAVOURITE
  className?: string
  primaryColor?: string
}

export const BookmarkIcon = ({
  itemId,
  type,
  className,
  primaryColor = '#131313',
}: IBookmarkIconProps) => {
  const user = useUser()
  const isFavourite = user.hasFavourite(Number(itemId), type)
  const registerModal = useRegisterModal()

  const handleOnClickFavourite = (e: React.MouseEvent<HTMLSpanElement>) => {
    e.stopPropagation()
    e.preventDefault()

    if (user.isReader) {
      user.toggleFavourite(Number(itemId), type)
    } else {
      registerModal.open()
    }
  }

  if (user.isSupplier || user.isAdmin) {
    return null
  }

  return (
    <button
      className={cx(s.bookmarkButton, className)}
      onClick={handleOnClickFavourite}
    >
      <BookmarkIconSVG
        svgProps={{
          ...(isFavourite && { fill: primaryColor }),
          className: s.bookmarkIcon,
        }}
        strokeColor={primaryColor}
      />
    </button>
  )
}
