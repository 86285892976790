import { FunctionComponent } from 'react'
import s from './card.module.css'
import { Image } from '../../../v2/atoms/image/image'
import { BookmarkIcon } from '../../../icons/bookmark-icon/bookmark-icon'
import { FAVOURITE, SLIDER_CLASS_NAME } from '@/constants'
import Link from 'next/link'
import { ReadMoreSVG } from '../../../svg/read-more/read-more'
import cx from 'classnames'
import { LearnMoreSVG } from '@/components/svg/learn-more/learn-more'
import { LocationSVG } from '@/components/svg/location/location'
import { SupplierLocation } from '@/types/Supplier'

export type CardSize = 'Small' | 'Medium' | 'Large'

export type CardType = 'Article' | 'Supplier' | 'Venue'

const getFavoriteType = (type: CardType) => {
  switch (type) {
    case 'Article':
      return FAVOURITE.ARTICLE
    case 'Supplier':
      return FAVOURITE.SUPPLIER
    case 'Venue':
      return FAVOURITE.VENUE
    default:
      throw new Error(`Unknown card type: ${type}`)
  }
}

export type CardProps = {
  header: string
  date?: string
  category: string
  imageUrl: string
  imageAlt: string
  imageLoading?: 'lazy' | 'eager'
  itemId: number | string
  path: string
  type: CardType
  size?: CardSize
  adjustToSlider?: boolean
  reverseVertical?: boolean
  primaryColor?: string
  locations?: SupplierLocation[]
}

const Card: FunctionComponent<CardProps> = ({
  header,
  date,
  category,
  locations,
  imageUrl,
  imageAlt,
  imageLoading,
  itemId,
  type,
  path,
  size = 'Medium',
  adjustToSlider = false,
  reverseVertical = false,
  primaryColor = '#000',
}) => {
  const favType = getFavoriteType(type)
  const reverseVerticalArticle = type === 'Article' && reverseVertical
  const reverseVerticalSupplier = type === 'Supplier' && reverseVertical

  let redirectPath = path

  if (type === 'Supplier') {
    redirectPath = `/wedding-suppliers/${path}`
  }

  if (type === 'Venue') {
    redirectPath = `/wedding-venues/${path}`
  }

  const renderHeaderInfo = (type: CardType) => {
    if (type === 'Article') {
      return (
        <p className={s.category}>
          <span>{category}</span> / {date}
        </p>
      )
    }

    if (type === 'Supplier' || type === 'Venue') {
      return (
        <div className={s.supplierCategory}>
          <LocationSVG fill={primaryColor} />{' '}
          {locations && locations.length > 0 && (
            <div className={s.locationWrapper}>
              {locations.slice(0, 4).map((loc, index) => (
                <span key={index}>
                  {loc.location.name}
                  {index < 3 && index < locations.length - 1 ? ', ' : ''}
                </span>
              ))}
              {locations.length > 4 && ` + ${locations.length - 4} more`}
            </div>
          )}
        </div>
      )
    }

    return null
  }

  const renderHoverSVG = (type: CardType) => {
    if (type === 'Article') {
      return <ReadMoreSVG className={s[`hoverSVG${size}`]} />
    }

    if (type === 'Supplier' || type === 'Venue') {
      return <LearnMoreSVG className={s[`hoverSVG${size}`]} />
    }

    return null
  }

  return (
    <div
      role="article"
      className={cx(
        { [SLIDER_CLASS_NAME]: adjustToSlider },
        { [s[`wrapperSlider${size}`]]: adjustToSlider },
        s[`wrapper${size}`]
      )}
    >
      <div className={s.bookmark}>
        <BookmarkIcon
          itemId={itemId}
          type={favType}
          primaryColor={primaryColor}
        />
      </div>

      <Link href={redirectPath} className={s.linkContainer}>
        <div
          className={cx(s[`container${size}`], {
            [s.reverseVerticalArticle]: reverseVerticalArticle,
            [s.reverseVerticalSupplier]: reverseVerticalSupplier,
          })}
        >
          <div
            className={cx(s.headerContainer, {
              [s.headerContainerCardMedium]: size === 'Medium',
            })}
            style={{
              color: primaryColor,
            }}
          >
            <h4
              className={cx(s.header, s[`header${size}`], {
                [s.supplierCardHeader]: reverseVerticalSupplier,
              })}
              style={{
                color: primaryColor,
              }}
            >
              {header}
            </h4>
            {renderHeaderInfo(type)}
          </div>
          <div
            className={cx(s.imageWrapper, {
              [s.imageSliderWrapper]: adjustToSlider,
              [s.imageWrapperMediumCard]: size === 'Medium',
            })}
          >
            <div className={s.shadowHover}>{renderHoverSVG(type)}</div>
            <Image
              alt={imageAlt}
              fill
              fit
              src={imageUrl}
              width={size === 'Small' ? 300 : 600}
              height={size === 'Small' ? 660 : 1320}
              className={s.image}
              loading={imageLoading}
            />
          </div>
        </div>
      </Link>
    </div>
  )
}

export default Card
